/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import { styled } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select, { components } from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SuiSelect
import styles from "components/SuiSelect/styles";
// Customized checkbox
const OrangeCheckbox = styled(Checkbox)(() => ({
  color: "#ff5308a1",
  "&.Mui-checked": {
    color: "#ff5308a1",
  },
}));

const SuiSelect = forwardRef(({ size, error, success, ...rest }, ref) => {
  const { light } = colors;
  // eslint-disable-next-line react/prop-types
  const Option = ({ label, data, getValue, ...props }) => {
    // eslint-disable-next-line react/prop-types
    const isSelected = getValue().some((t) => t.value === data.value);
    return (
      <div>
        <components.Option {...props}>
          <OrangeCheckbox checked={isSelected} label={label} />
          <span>{label}</span>
        </components.Option>
      </div>
    );
  };

  return (
    <Select
      {...rest}
      ref={ref}
      components={{ Option }}
      styles={styles(size, error, success)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
      allowSelectAll
    />
  );
});

// Setting default values for the props of SuiSelect
SuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the SuiSelect
SuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default SuiSelect;
